import React, { useState } from 'react'
import { useMeasure } from 'react-use'
import { scaleOrdinal } from '@visx/scale'
import tw, { theme } from 'twin.macro'

import OverviewHeader from '../OverviewHeader'
import CockpitList from '../CockpitList'
import ChartToggle from '../ChartToggle'
import RecyclableChart from './RecyclableChart'
import BarStackChart from '../BarStackChart'
import DownloadCard from '../DownloadCard'
import useData from '../../data/useData'

const CHART_BOTTOM_OFFSET = 40

const WEIGHT = 't'
const RATIO_RECYCLING = 'ratio recycling'
const EMISSION = 'CO2e t/t'

function getEmission(emissionInT, t) {
  return emissionInT * t
}

function getDepartmentUsageData(orgCodes, paper) {
  const data = paper.map((d) => ({
    date: d.year,
    department: orgCodes[d.department],
    consumption: d[WEIGHT],
    emission: getEmission(d[EMISSION], d[WEIGHT]),
  }))

  return data
}

function getDepartmentColorScale(orgCodes, orgColors) {
  // Sort codes and colors by key
  const sortedCodes = Object.keys(orgCodes)
    .sort()
    .map((k) => orgCodes[k])
  const sortedColors = Object.keys(orgColors)
    .sort()
    .map((k) => orgColors[k])

  const colorScale = scaleOrdinal({
    domain: sortedCodes,
    range: sortedColors,
  })

  return colorScale
}

function getRecyclableData(paper, toggleY) {
  const data = paper.map((d) => {
    const totalY = toggleY ? d[WEIGHT] : getEmission(d[EMISSION], d[WEIGHT])
    const recyclable = d[RATIO_RECYCLING] * totalY

    return {
      date: d.year,
      recyclable,
      'not recyclable': totalY - recyclable,
    }
  })

  const dataByYear = data.reduce((acc, curr) => {
    const { date, recyclable, 'not recyclable': notRecyclable } = curr

    const totalRecyclable = acc[date]?.['recyclable']
      ? acc[date]['recyclable'] + recyclable
      : recyclable
    const totalNotRecyclable = acc[date]?.['not recyclable']
      ? acc[date]['not recyclable'] + notRecyclable
      : notRecyclable

    return {
      ...acc,
      [date]: {
        date,
        recyclable: totalRecyclable,
        'not recyclable': totalNotRecyclable,
      },
    }
  }, {})

  return Object.values(dataByYear)
}

const getYProp = (toggleY) => (toggleY ? 'consumption' : 'emission')

const departmentConsumptionAccessors = {
  x: (d) => d.date,
  y: (d) => d.consumption,
  z: (d) => d.department,
}

function PaperToggle({ selected, onChange }) {
  return (
    <ChartToggle
      selected={selected}
      onChange={onChange}
      rightLabel="Consumption"
      leftLabel="Emission"
    />
  )
}

export default function Paper() {
  const { paper, orgCodes, orgColors } = useData()
  const [c1Ref, { width: c1Width, height: c1Height }] = useMeasure()
  const [toggleY, setToggleY] = useState(true)

  return (
    <div tw="flex-1 lg:grid lg:grid-cols-4 lg:overflow-x-auto">
      <div tw="lg:col-span-3 lg:grid lg:grid-cols-2">
        <CockpitList tw="overflow-x-hidden">
          <div
            css={[
              `height: calc(100vh - ${theme`spacing.20`});`,
              tw`flex flex-col`,
            ]}
          >
            <OverviewHeader title={<>Paper consumption ({unit(toggleY)})</>} />
            <div tw="flex-1 h-0" ref={c1Ref}>
              {c1Height && (
                <BarStackChart
                  width={c1Width}
                  height={c1Height - CHART_BOTTOM_OFFSET}
                  data={getDepartmentUsageData(orgCodes, paper)}
                  accessors={departmentConsumptionAccessors}
                  unit={unit(toggleY)}
                  xProp="date"
                  yProp={getYProp(toggleY)}
                  toggle={
                    <PaperToggle
                      selected={toggleY}
                      onChange={() => setToggleY((prev) => !prev)}
                    />
                  }
                  color={getDepartmentColorScale(orgCodes, orgColors)}
                  tw="px-3 pt-4 pb-3"
                />
              )}
            </div>
          </div>
        </CockpitList>

        <CockpitList tw="overflow-hidden">
          <div
            css={[
              `height: calc(100vh - ${theme`spacing.20`});`,
              tw`flex flex-col`,
            ]}
          >
            <OverviewHeader title={<>Recyclable ({unit(toggleY)})</>} />
            <div tw="flex-1 h-0">
              {c1Height && (
                <RecyclableChart
                  width={c1Width}
                  height={c1Height - CHART_BOTTOM_OFFSET}
                  data={getRecyclableData(paper, toggleY)}
                  unit={unit(toggleY)}
                  toggle={
                    <PaperToggle
                      selected={toggleY}
                      onChange={() => setToggleY((prev) => !prev)}
                    />
                  }
                  tw="px-3 pt-4 pb-3"
                />
              )}
            </div>
          </div>
        </CockpitList>
      </div>

      <CockpitList tw="bg-grey-6">
        <OverviewHeader title="EXPLORE" />
        <DownloadCard field="paper" title="Paper" />
      </CockpitList>
    </div>
  )
}

function unit(toggleY) {
  return toggleY ? 't' : 'tCO₂e'
}
