import React from 'react'

import Layout from '../../components/Layout'
import Paper from '../../components/Paper'

export default function PaperPage() {
  return (
    <Layout>
      <Paper />
    </Layout>
  )
}
