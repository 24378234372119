import React, { memo, useCallback, useMemo } from 'react'
import { BarStack } from '@visx/shape'
import { Group } from '@visx/group'
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale'
import { schemeTableau10 as schemeSet } from 'd3-scale-chromatic'
import { format } from 'd3-format'
import 'twin.macro'

import Legend from '../Legend'
import OverviewAxes from '../OverviewAxes'
import OverviewTooltipContent from '../OverviewTooltipContent'
import OverviewChartTitle from '../OverviewChartTitle'
import useSVGTooltip from '../../connectors/useSvgTooltip'
import { getMaxYValue } from '../../util'

const defaultMargin = {
  top: 30,
  left: 70,
  right: 20,
  bottom: 20,
}

const valueFormatter = format('.1f')
const xProp = 'date'
const getX = (d) => d[xProp]

function getSortedKeys(data) {
  const keys = [
    ...new Set(Object.keys(data[0]).filter((k) => k !== xProp)),
  ].sort()
  return keys
}

function BarStackChart({
  width,
  height,
  margin = defaultMargin,
  data,
  unit,
  toggle,
  ...rest
}) {
  const xMax = width - margin.left - margin.right
  const yMax = height - margin.top - margin.bottom

  const xScale = useMemo(
    () =>
      scaleBand({
        domain: data.map(getX).sort(),
        range: [0, xMax],
        padding: 0.2,
      }),
    [data, xMax]
  )

  const yScale = useMemo(() => {
    return scaleLinear({
      domain: [0, getMaxYValue(data, xProp)],
      range: [yMax, 0],
      nice: true,
    })
  }, [data, yMax])

  const sortedKeys = useMemo(() => getSortedKeys(data), [data])
  const colorScale = useMemo(
    () =>
      scaleOrdinal({
        domain: sortedKeys,
        range: schemeSet,
      }),
    [sortedKeys]
  )

  const style = { width }

  const {
    containerRef,
    handlePointer,
    TooltipInPortal,
    hideTooltip,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    tooltipData,
    tooltipStyles,
  } = useSVGTooltip()

  const handleTooltip = useCallback(
    (e, { bar, key }) => {
      const sum = Object.entries(bar.data)
        .filter(([key]) => key !== xProp)
        .reduce((acc, [, value]) => acc + value, 0)

      handlePointer(e, {
        label: key,
        value: valueFormatter(bar.data[key]),
        sum: valueFormatter(sum),
        unit,
      })
    },
    [handlePointer, unit]
  )

  return (
    <div style={style} tw="relative" {...rest}>
      {toggle}
      <svg width={width} height={height} ref={containerRef}>
        <Group top={margin.top} bottom={margin.bottom} left={margin.left}>
          <BarStack
            data={data}
            keys={sortedKeys}
            x={getX}
            xScale={xScale}
            yScale={yScale}
            color={colorScale}
          >
            {(barStacks) =>
              barStacks.map((barStack) =>
                barStack.bars.map((bar) => {
                  const barWidth = bar.width > 100 ? 100 : bar.width
                  return (
                    bar.y && (
                      <rect
                        key={`bar-stack-${barStack.index}-${bar.index}`}
                        x={bar.x + bar.width / 2 - barWidth / 2}
                        y={bar.y}
                        height={bar.height}
                        width={barWidth}
                        fill={bar.color}
                        onPointerMove={(e) => handleTooltip(e, bar)}
                        onPointerLeave={hideTooltip}
                      />
                    )
                  )
                })
              )
            }
          </BarStack>

          <OverviewAxes
            xScale={xScale}
            yScale={yScale}
            top={yMax}
            leftNumTicks={yScale.ticks().length / 2}
          />
          <OverviewChartTitle height={yMax} unit={unit} />
        </Group>

        {tooltipOpen && (
          <>
            <TooltipInPortal
              left={tooltipLeft}
              top={tooltipTop}
              style={tooltipStyles}
            >
              <OverviewTooltipContent tooltipData={tooltipData} />
            </TooltipInPortal>
          </>
        )}
      </svg>

      <div tw="absolute top-10 right-6">
        <Legend scale={colorScale} isHorizontal={false} />
      </div>
    </div>
  )
}

export default memo(BarStackChart)
